@tailwind base;
@tailwind components;
@tailwind utilities;

button.btn.btn-primary {
  background-color: var(--bs-btn-bg);
}
.login-page-wrapper {
  height: 100vh;
  overflow-y: hidden;
}

@media only screen and (max-width: 767px) {
  .login-page-wrapper {
    height: 100%;
    overflow-y: scroll;
  }
}

.swiper-scrollbar .swiper-scrollbar-horizontal{
    padding: 15px;
}
.swiper-horizontal>.swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal{
    position: absolute !important;
    left: 5 !important;
    bottom: 98vh !important;
}

.swiper-scrollbar-drag {
  background-color: #f2f2f2 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.flash-btn{
  transition: transform 0.3s ease-in-out;
  animation: btnpulse 0.8s infinite alternate;
}
.flash-btn:hover {
  transform: scale(1.1);
}

@keyframes btnpulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
