.logo-login-top {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 25px;
}
.shadowed {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.swiper-scrollbar.swiper-scrollbar-horizontal{
  max-width: 680px;
}

.img-slider {
  margin-left: 0;
  margin-right: auto;
}
.form-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  font-weight: 500;
}
.form-img {
  margin-right: 10px;
  width: 2.5em;
}
.form-group {
  padding: 18px;
  padding-top: 0;
}
.form-group label{
    color: #333333;
}
.form-button {
  padding: 10px 15px;
  transition: 0.3s;
}
.form-button:hover {
  background-color: #0b8840 !important;
}

.top-bar {
  padding: 20px;
}

.outlined-button {
  padding: 10px 15px;
  width: 9em;
  border: 1px solid #82b78d;
  border-radius: 5px;
  color: #141414;
  font-weight: 400;
}
.outlined-button:hover {
  background-color: #82b78d;
  color: #141414;
}

.form-control{
    border: none;
    outline: none;
    border-bottom: 1px solid #82b78d;
    border-radius: 0;
    padding-left: 5px;
}
.form-control:focus{
    box-shadow: none;
    outline: none;
}
