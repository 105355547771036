.cust-notes {
  /* width: 100%; */
  padding: 20px;
}

.cart-end {
  margin-top: 20px;
}
.basket {
  bottom: 0%;
  background-color: #fff;
  background-image: url("../../../../public/cart-icon.svg");
  background-position: 0 center;
  background-size: 144px;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  position: fixed;
  right: 6vw;
  transition: all ease 300ms;
  width: 72px;
  z-index: 999 !important;
}

.icon {
  position: absolute;
  top: -20px;
  right: 20px;
  color: #fff;
  border-radius: 50%;
  padding: 4px;
  width: 30px;
  font-size: 17px;
  background-color: #d53232;
}

.green-box {
  background-color: #0d5416;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.align-center {
  text-align: center;
}

.box-container {
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #ccc; */
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.cart-item-discount,
.cart-item-name,
.cart-item-price,
.cart-item-code {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
}

.cart-item-discount {
  color: #d53232;
}

.cart-item-quantity {
  font-size: 1rem;
  margin: 0;
}

.cart-item-quantity-btn {
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.cart-item-remove-btn {
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 1.5rem;
}

.total-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 10px;
  background-color: #f5f5f5;
  margin-bottom: 15px;
}

.total-text {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: right;
}

.clear-button {
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
}

.cart-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  background: #fff;
  z-index: 9999999999;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow-y: scroll;
}

.cart-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.close-icon {
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url("../../../../public/close.svg");
}

.cart-list {
  list-style: none;
  padding: 0;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #ccc; */
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.cart-item-details {
  flex: 1;
  margin-right: 10px;
  padding: 10px;
}

.cart-item-quantity {
  font-size: 1rem;
  margin: 0;
}

.cart-item-controls {
  display: flex;
  align-items: center;
}

.cart-item-control-btn {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  font-size: 1.2rem;
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
}

.cart-item-control-btn:hover {
  background-color: #ccc;
  color: #000;
}

.cart-item-remove-btn {
  background-color: transparent;
  color: red;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: 10px;
}

.cart-item-remove-btn:hover {
  color: #000;
}

.bg-kedifapgreen-200 {
  float: left;
}

.custom-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px;
}
.order-custom-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px;
}

.item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-right: 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.pharmacy-box {
  margin-bottom: 15px;
}
