@media (max-width: 1024px) {
  /* ============ LOGIN PAGE RESPONSIVE =========== */
  .login-slider {
    display: none;
  }
  .form-wrapper {
    padding: 5px !important;
  }

  .form-wrapper .logo-login-top {
    width: 60% !important;
    margin-left: 0px;
  }

  .login-page-wrapper .form-header {
    padding-bottom: 15px !important;
  }

  .login-page-wrapper .form-group {
    padding: 0px !important;
    padding-bottom: 15px !important;
  }

  /* ========= HOME PAGE RESPONSIVE ============ */
  .header-navbar {
    display: none !important;
  }
  .header-slider {
    display: none !important;
  }
  .banners .banner-slider {
    display: none !important;
  }

  /* HIMANSHU SHARMA */

  /* ========= HIDE LIVE CHAT  ============ */

  /* #chat-widget-container {
    display: none !important;
  } */

  /* ========= CATALOGUE PAGE RESPONSIVE ============ */
  .custom-filters {
    /* flex-direction: column !important;
    align-items: flex-start !important; */
    flex-wrap: wrap !important;
    padding: 10px 5px !important;
  }
  /* .custom-filters p {
    overflow: visible !important;
    word-wrap: break-word !important;
  } */
  .custom-filters p.info-text {
    display: none !important;
  }

  .custom-filters .MuiFormControlLabel-root{
    margin-right: 5px !important;
  }
  .custom-filters .MuiSwitch-thumb{
    width: 15px !important;
    height: 15px !important;
  }
  .custom-filters .MuiSwitch-root{
    width: 47px !important;
    height: 35px !important;
  }

  /* ========= HIDE THE TOP RIGHT FILTER OF MATERIAL TABLE ============ */
  .MuiToolbar-root .MuiBox-root .MuiButtonBase-root:nth-child(1) {
    display: none !important;
  }
  .MuiToolbar-root .MuiBox-root .MuiButtonBase-root:nth-child(2) {
    display: none !important;
  }
  .MuiToolbar-root .MuiBox-root .MuiButtonBase-root:nth-child(5) {
    display: none !important;
  }

  /* ========= HIDE THE MATERIAL TABLE HEADER SEARCH FILTER  ============ */

  .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root .MuiCollapse-root {
    display: none !important;
  }

  /*  =========  MANAGE CART POSITION  =========  */
  .basket {
    top: 25px !important;
    right: 10px !important;
    height: 40px !important;
    display: none !important;
  }
  .icon {
    height: 30px !important;
    width: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    left: 0 !important;
    display: none !important;
  }

  /*  PRODUCT DOCUMENT INFO */
  .modal-wide {
    width: 96% !important;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }
  .items-center {
    align-items: center !important;
  }

  /*  PRODUCT DOCUMENT INFO */
  .table {
    overflow: hidden !important;
  }

  .modal-body {
    overflow: scroll !important;
  }

  /* HIDE THE CUSTOM FILTERS LIKE LAST MONTH, LAST 4 MONTHS OF ORDER */
  /* .order-custom-filters {
    display: none !important;
  } */

  #chat-widget-container {
    position: fixed !important;
    top: 0;
  }
  #chat-widget-container button div {
    width: 25px !important;
    height: 25px !important;
  }
}

@media (max-width: 575px) {
  .cart-container {
    width: auto !important;
  }
  
  .part-description {
    font-size: 12px;
    width: 220px;
    /* white-space: nowrap;
    text-overflow: ellipsis; */
    overflow: hidden;
  }
  .table-cart-button {
    height: 25px !important;
    width: 25px !important;
    font-size: 13px !important;
  }
  .parts-preview svg{
    font-size: 28px !important;
  }
}
@media (max-width: 478px) {
  .part-description {
    font-size: 12px;
    width: 130px;
    /* white-space: nowrap;
    text-overflow: ellipsis; */
    overflow: hidden;
  }
}
