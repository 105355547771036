.cart-template {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 30px 10px 0px;
}
.cart-template-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #cbd1cc8d;
    padding: 10px 30px 10px 30px;
}
.img-col{
    width: 50px;
    height: 50px;
}

img.w-full.rounded._3xuz3 {
    max-width: 66px;
    width: 50px;
    height: 55px;
}

.thumbnail {
  color: #1f79d5;
  cursor: "pointer";
  font-size: "35px";
  }
  
  .thumbnail img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  #ReactSimpleImageViewer {
    z-index: 100;
  }